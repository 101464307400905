<template>
  <div class="certificates-by-entities" v-if="hasPerm('certificates.view_certificate')">
    <page-header
      title="État des justificatifs par famille" icon="fa fa-certificate" :links="getLinks()"
    ></page-header>
    <div class="sub-header" v-show="initialLoadingDone">
      <b-row>
        <b-col cols="8">
          <h2>
            <counter-label :counter="entities.length" label="famille"></counter-label>
          </h2>
        </b-col>
        <b-col cols="4" class="text-right">
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-input
            id="name"
            v-model="nameFilter"
            placeholder="filtrer par nom de famille"
          >
          </b-form-input>
        </b-col>
        <b-col cols="3">
          <b-form-select
            id="filter-type"
            v-model="selectedFilter"
            @change="onFilterSelected"
            >
            <b-form-select-option
              :value="item.id"
              v-for="item in filterChoices"
              :key="item.id"
            >
              {{ item.name }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="3">
          <b-form-select
            id="certificate-types"
            v-model="selectedCertificateType"
            @change="onCertificateTypeSelected"
            >
            <b-form-select-option
              :value="item"
              v-for="item in certificateTypes"
              :key="item.id"
            >
              {{ item.name }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col>
          <b-form-checkbox id="showOptional" v-model="showOptional">
            Afficher les documents optionnels
          </b-form-checkbox>
        </b-col>
      </b-row>
    </div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div ref="printMe">
      <div class="empty-text"  v-if="initialLoadingDone && !isLoading(loadingName) && entities.length === 0">
        Aucune famille
      </div>
      <table
        class="table table-bordered small-text"
        v-if="initialLoadingDone && !isLoading(loadingName) && entities.length"
      >
        <thead>
          <tr>
            <th>Famille</th>
            <th>Année</th>
            <th>Document</th>
            <th>Individu</th>
            <th>État</th>
          </tr>
        </thead>
        <tbody v-for="item in entities" :key="item.id">
          <tr v-for="(cert, $index) in filteredCertificates(item)" :key="cert.id">
            <td v-if="$index === 0" :rowspan="filteredCertificates(item).length">
              <a :href="getLinkToEntityUrl(item.id)" @click.prevent="showEntitySidebar(item.entity)">
                <span class="family-numbers">{{ item.entity.id }}</span> {{ item.entity.name }}
              </a>
            </td>
            <td><span class="badge badge-light">{{ cert.schoolYear.name }}</span></td>
            <td>{{ cert.certificateType.name }}</td>
            <td><span v-if="cert.individual">{{ cert.individual.firstAndLastName() }}</span></td>
            <td>
              <span v-if="cert.certificate">
                <span v-if="cert.certificate.verifiedOn">
                  <span v-if="cert.certificate.refused" class="badge badge-danger">
                    Refusé le {{ cert.certificate.createdOn | dateToString }}
                  </span>
                  <span v-else class="badge badge-success">
                    Vérifié le {{ cert.certificate.createdOn | dateToString }}
                  </span>
                </span>
                <span v-else class="badge badge-primary">
                  A Vérifier
                </span>
              </span>
              <span v-else>
                <span class="badge badge-dark" v-if="cert.certificateType.isRequired">
                  Manquant
                </span>
                <span  class="badge badge-light" v-else>
                  Optionnel
                </span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import { BackendApi, openDocument } from '@/utils/http'
import { makeChoice } from '@/types/base'
import { makeCertificate, makeCertificateType } from '@/types/certificates'
import { compareStrings } from '@/utils/sorting'
import { makeEntity, makeIndividual } from '@/types/people'
import { makeSchoolYear } from '@/types/schools'
import router from '@/router'

export default {
  name: 'CertificatesByEntities',
  mixins: [BackendMixin],
  components: {
    CounterLabel,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'CertificatesByEntities',
      certificates: [],
      certificatesCount: 0,
      initialLoadingDone: false,
      selectedCertificateType: makeCertificateType(),
      certificateTypes: [],
      selectedFilter: 0,
      showOptional: false,
      nameFilter: '',
      filterChoices: [
        makeChoice({ id: 0, name: 'Tous', }),
        makeChoice({ id: 1, name: 'Dossiers complets', }),
        makeChoice({ id: 2, name: 'Dossiers incomplets', })
      ],
      entitiesMap: new Map(),
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
  },
  computed: {
    entities() {
      let entities = Array.from(this.entitiesMap.values())
      if (this.nameFilter) {
        const nameFilter = this.nameFilter.toUpperCase()
        entities = entities.filter(elt => elt.name.toUpperCase().indexOf(nameFilter) >= 0)
      }
      if (this.selectedFilter === 1) {
        entities = entities.filter(entity => this.isCompleted(entity))
      } else if (this.selectedFilter === 2) {
        entities = entities.filter(entity => !this.isCompleted(entity))
      }
      return entities.sort(
        (elt1, elt2) => compareStrings(elt1.name, elt2.name)
      )
    },
  },
  mounted() {
    const certificateType = +(this.$route.query ? this.$route.query.type : 0)
    const filtering = +(this.$route.query ? this.$route.query.filter : 2)
    this.onLoaded(certificateType, filtering)
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    filteredCertificates(item) {
      let certificates = item.certificates
      if (this.selectedCertificateType && this.selectedCertificateType.id) {
        certificates = certificates.filter(
          elt => elt.certificateType.id === this.selectedCertificateType.id
        )
      }
      if (!this.showOptional) {
        certificates = certificates.filter(
          elt => elt.certificateType.isRequired || !!elt.certificate
        )
      }
      return certificates
    },
    isCompleted(item) {
      const certificates = this.filteredCertificates(item)
      const accepted = certificates.filter(
        itm => itm.certificate && (itm.certificate.verifiedOn) && (!itm.certificate.refused)
      )
      return (certificates.length === accepted.length)
    },
    async onLoaded(certificateType, filtering) {
      this.certificateTypes = [makeCertificateType({ name: 'Tous les types de documents', })]
      await this.loadCertificateTypes()
      let theIndex = 0
      if (certificateType) {
        theIndex = this.certificateTypes.map(elt => elt.id).indexOf(certificateType)
        theIndex = (theIndex < 0) ? 0 : theIndex
      }
      this.selectedCertificateType = this.certificateTypes[theIndex]
      this.selectedFilter = (filtering ? +filtering : 0)
      await this.loadElements(1)
    },
    onCertificateTypeSelected() {
    },
    onFilterSelected() {
    },
    async loadElements() {
      this.startLoading(this.loadingName)
      let url = '/certificates/api/certificates-by-entities/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        const entitiesMap = new Map()
        for (const elt of resp.data) {
          let item = null
          if (!entitiesMap.has(elt.entity.id)) {
            item = {
              id: elt.entity.id,
              name: elt.entity.name,
              certificates: [],
              entity: makeEntity(elt.entity),
            }
          } else {
            item = entitiesMap.get(elt.entity.id)
          }
          item.certificates.push(
            {
              id: item.certificates.length + 1,
              schoolYear: makeSchoolYear(elt.school_year),
              individual: elt.individual ? makeIndividual(elt.individual) : null,
              certificateType: makeCertificateType(elt.certificate_type),
              certificate: elt.certificate ? makeCertificate(elt.certificate) : null,
            }
          )
          entitiesMap.set(elt.entity.id, item)
        }
        this.entitiesMap = entitiesMap
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.initialLoadingDone = true
      this.endLoading(this.loadingName)
    },
    async loadCertificateTypes() {
      try {
        let url = '/certificates/api/certificate-types/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.certificateTypes = this.certificateTypes.concat(
          resp.data.map(elt => makeCertificateType(elt))
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinkToEntityUrl(entityId) {
      return router.resolve(this.getLinkToEntity(entityId)).href
    },
    getLinkToEntity(entityId) {
      return { name: 'families-detail', params: { entityId: '' + entityId, }, }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'etat-justificatifs-' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'etat-justificatifs-' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    viewCertificates() {
      this.$router.push({ name: 'certificates', })
    },
    getLinks() {
      let links = []
      const isNotValid = this.isLoading(this.loadingName) || (this.entities.length === 0)
      links = [
        {
          id: 1,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'far fa-file-excel',
          cssClass: isNotValid ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: isNotValid ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 3,
          label: 'Liste des justificatifs',
          callback: this.viewCertificates,
          icon: 'fa fa-table',
          cssClass: 'btn-secondary',
        }
      ]
      return links
    },
  },
}
</script>

<style scoped lang="less">
</style>
